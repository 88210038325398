<template>
  <div>
    <div class="container-fluid" style="padding: 20px 20px 20px 20px;">
      <div class="row">
        <div class="col">
          <transition name="fade">
            <button v-if="backButton" @click="goBack()" class="btn" v-bind:class="darkModeBtn">Go Back</button>
          </transition>
        </div>
        <div class="col d-flex justify-content-end">
          <router-link to="/account/orders" class="btn" v-bind:class="darkModeBtn">View Orders</router-link>
        </div>
      </div>
      <transition name="fade">
        <router-view class="mt-3"></router-view>
      </transition>
    </div>

    <ShoppingCart></ShoppingCart>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
import { darkModeStyle } from '../../../assets/js/darkMode';

export default {
  components: {
    ContentLoader,
    ShoppingCart: () => import('@components/ShoppingCart.vue'),
  },
  data() {
    return {
      backButton: true,
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    }
  },
  methods: {
    goBack() {
      if (this.$route.name == 'order-menu-items') {
        this.$router.push({ name: 'order-menu-categories' })
      }

      if (this.$route.name == 'order-menu-variation') {
        this.$router.go(-1);
        // this.$router.push({ name: 'order-menu-items' })
      }
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'order-menu-categories') {
        this.backButton = false
        return
      }

      this.backButton = true
    }
  },
  mounted() {
    if (this.$route.name == 'order-menu-categories') {
      this.backButton = false
      return
    }

    this.backButton = true
  }
}
</script>
